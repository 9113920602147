:root {
  --controls-section-height: 80px;
}

.MeetingControls_Container {
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 100vw;
  min-height: var(--controls-section-height);
  background-color: white;
}
.aspectRatio16_9_Container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  transition: 0.25s all;
  cursor: pointer;
  background-color: black;
}

.aspectRatio16_9_child {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: black;
}
